import React from "react";
import { Carousel } from "antd";
import { apiHost } from "config";
import dummyCor from "asserts/img/dummy-cro.png";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

export const CustomCarousel = ({
  data,
  height,
  autoplay,
  width,
  arrows,
  arrowColor,
}) => {
  if (data) {
    return (
      <div style={{ position: "relative" }}>
        <Carousel
          arrows={arrows}
          nextArrow={<IoIosArrowDroprightCircle color={arrowColor || "#fff"} />}
          prevArrow={<IoIosArrowDropleftCircle color={arrowColor || "#fff"} />}
          autoplay={autoplay}
        >
          {data?.map((arr, i) => (
            <div key={i} id="carousel" style={{ textAlign: "center" }}>
              {arr?.image_path && arr?.image_path.startsWith("https") ? (
                <>
                  <img
                    key={i}
                    src={arr.image_path}
                    alt=""
                    style={{ width: width || "100%", height: height || "auto" }}
                    loading="lazy"
                  />
                </>
              ) : (
                <>
                  {arr.keys ? (
                    <img
                      key={i}
                      src={arr.image_path}
                      alt=""
                      style={{
                        width: width || "100%",
                        height: height || "auto",
                      }}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      key={i}
                      src={
                        arr?.image_path
                          ? `${apiHost}/${arr.image_path}`
                          : dummyCor
                      }
                      alt=""
                      style={{
                        width: width || "100%",
                        height: height || "auto",
                      }}
                      loading="lazy"
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
};
