import React from "react";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";

export const PrivacyPage = () => {
  return (
    <>
      <AppBar />
      <div
        style={{
          background: "#d3d3d3",
          color: "red",
          padding: "100px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <h1>Privacy Policy</h1>
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <h1>Introduction</h1>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We are committed to protecting the privacy of personal information and
          to handling the personal information in a responsible manner in
          accordance with the relevant privacy legislation in the countries of
          our operations (referred to as privacy legislation). This Privacy
          Policy explains how we collect, use and disclose your personal
          information, how you may access that information and how you may seek
          the correction of any information. It also explains how you may make a
          complaint about a breach of privacy legislation. From time to time we
          may make changes to our policy, processes and systems in relation to
          how we handle your personal information. We will update this Privacy
          Policy to reflect any changes. Those changes will be available on our
          Web site.
        </p>
        <h1>Collection</h1>

        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We collect information as furnished to us and/or collected that is
          necessary and relevant for providing our core services and
          products.This information may include your name, address, date of
          birth, gender. This information may be stored on our computer records
          system and/or in printed records (collectively, information). Wherever
          practicable we will only collect information from you personally. We
          collect information in various ways, such as over the phone or in
          writing, in person, or over the Internet. We will notify you or will
          endeavor to make you aware that information is or has being collected.
          We will endeavor to inform you of the purpose of collection and will
          provide you with access to our privacy policy that contains details
          about access, correction and complaints. We advise that if you notify
          us that you do not wish to provide the information to us we may not be
          able to provide you with the product or service you have asked for or
          give you the level of service you expect.
        </p>
        <h1>Use and Disclosure</h1>

        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We will treat your personal information as strictly private and
          confidential. We will only use or disclose it for purposes directly
          related to our core business, or in ways that you would reasonably
          expect that we may use it in the course of our business. We do not
          sell, trade, or otherwise transfer to outside parties your personally
          identifiable information. This does not include trusted third parties
          who assist us in operating our website, conducting our business, or
          servicing you, so long as those parties agree to keep this information
          confidential and the data is used only for the activities we have
          asked them to perform. We may also release your information when we
          believe such release is appropriate to comply with the law, or protect
          ours or third party rights, property, or safety. However,
          non-personally identifiable information including statistical data may
          be provided to other parties for marketing, advertising, research or
          other uses.{" "}
        </p>
        <h1>Data Security</h1>

        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We maintain adequate technical and organizational security measures to
          protect personal data from loss, misuse, and unauthorized access,
          disclosure, alteration, or destruction. If you would like to: access,
          correct, amend or delete any personal information we have about you,
          register a complaint, or simply want more information contact our
          Privacy Compliance Officer at viaoled@gmail.com or posting to
        </p>
      </div>
      <Footer />
    </>
  );
};
