import React from "react";
import { AppBar } from "components/AppBar";
// import { handleToast } from "components/toast";
import // Card,
// Tooltip,
// Row,
// Col,
// Drawer,
// Rate,
// Checkbox,
// Tabs,
// Radio,
// Input,
// notification,
// Button,
"antd";
import { BsGeoAlt, BsWhatsapp, BsTelephone } from "react-icons/bs";
// import { AiOutlineMail } from "react-icons/ai";
// import { CustomInput } from "components/fields";
import { Footer } from "components/Footer";
import logo from "asserts/img/tvlogo.webp";
// const { TextArea } = Input;

export const AboutPage = () => {
  // const [api, contextHolder] = notification.useNotification();

  return (
    <>
      {/* {contextHolder} */}
      <AppBar />
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src={logo} style={{ width: "50%" }} alt="" loading="lazy" />
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          VIAO, founded in 2012 in India, has quickly emerged as a prominent
          television brand with a distinct vision. Its primary objective has
          been to craft high-tech products specifically catering to the needs
          and preferences of the urban youth, making VIAO LED TVs a preferred
          choice for many. Over the years, VIAO has painstakingly developed a
          reputation for its meticulous control over the end-to-end supply
          chain, ensuring the highest standards of quality and performance in
          its products. This commitment to excellence has been a key factor in
          VIAO's success, solidifying its position in the competitive consumer
          electronics market. VIAO's LED TVs are known not only for their
          technological prowess but also for their sleek and modern designs,
          reflecting the brand's dedication to aesthetics and user experience.
          This attention to detail has resonated with discerning consumers who
          seek both style and substance in their home entertainment devices. In
          addition to its product quality, VIAO has built a robust network of
          service commitments across the country. This network ensures that
          customers can access prompt and reliable support, enhancing their
          overall experience with VIAO products. With each passing year, VIAO
          continues to innovate and evolve, pushing the boundaries of technology
          and design. As it caters to the dynamic needs of the urban youth, VIAO
          remains a brand to watch in the ever-evolving world of consumer
          electronics, promising a future filled with even more high-tech
          innovations and exceptional products.
        </p>
        <h3 style={{ fontSize: "2rem", width: "100%", textAlign: "center" }}>
          All Over Tamilnadu Dealers Point Available
        </h3>
        <h1>Our Branches</h1>
        <div
          style={{
            fontSize: "2rem",
            display: "flex",
            flexWrap: "wrap",
            gap: 48,
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <BsGeoAlt />
              <p>AVINASHI</p>
            </div>
            <p style={{ fontWeight: 500, margin: 0 }}>+91 9514449983</p>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <BsGeoAlt />
              <p>ERODE</p>
            </div>
            <p style={{ fontWeight: 500, margin: 0 }}>+91 9514449984</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
