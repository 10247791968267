import React from "react";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";

export const DamagePage = () => {
  return (
    <>
      <AppBar />
      <div
        style={{
          background: "#d3d3d3",
          color: "red",
          padding: "100px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <h1>Shipping and Damage Policy</h1>
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          we give free shipping on some of our products
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Delivery duration depends upon the destination. Approximately, orders
          in India are delivered within 3-7 business days. Metro cities are
          delivered in 2-5 business days
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          The customer can update the address only before the product is shipped
          and necessary changes will be made by us. You can call costumer care
          and send an email about the changes you want to make and we will make
          it happen.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Unfortunately, COD is not available yet. All the payment for the
          orders shall be made online.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          VIAO is in no way responsible for any damage/fault if product was
          opened without un boxing video or installed through any unauthorized
          person.
        </p>
      </div>
      <Footer />
    </>
  );
};
