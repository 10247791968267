import React from "react";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";

export const CancelationPage = () => {
  return (
    <>
      <AppBar />
      <div
        style={{
          background: "#d3d3d3",
          color: "red",
          padding: "100px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <h1>Cancelation policy</h1>
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          The following policies apply for all products fulfilled by VIAO
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Once the shipment is shipped, it can’t be cancellation in the transit
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We only accept returns if the item shipped to you are defective or
          damaged.
        </p>
        <h1>In case of any damaged or defective product:</h1>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Customer is required to raise the concern within 24 hours from
          delivery to our Customer Care Center or mail to viaoled@gmail.com.in
          for next steps.<br></br> VIAO will send you a new replacement unit as
          soon as we receive the return package.<br></br> VIAO is in no way
          responsible for any damage/fault if product was opened or installed
          through any unauthorized person.<br></br> In case of issues during the
          warranty period, contact our service center.<br></br> We do not offer
          any cash refunds.<br></br> Buy VIAO Smart LED TV Online.
        </p>
      </div>
      <Footer />
    </>
  );
};
