import React, { useState, useEffect } from "react";
import {
  Card,
  Tooltip,
  Row,
  Col,
  Drawer,
  // Rate,
  Checkbox,
  Tabs,
  Radio,
} from "antd";
import { AppBar } from "components/AppBar";
import { BsArrowsFullscreen, BsCart } from "react-icons/bs";
// import { AiFillStar } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { fetchProducts } from "api/product";
import dummytv from "asserts/img/dummy-log.png";
import { CustomCarousel } from "components/Carousel";
import { apiHost } from "config";
import { notification } from "antd";
import { handleToast } from "components/toast";
import { Loader } from "components/loader";
import noData from "asserts/img/noData.jpg";

const { Meta } = Card;

export const ProductPage = ({ props }) => {
  const [model, setModel] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [filter, setFilter] = useState({
    rate: {},
    discount: {},
  });
  const [cardData, setCardData] = useState({});
  const [productData, setProductData] = useState([]);
  const [params, setParams] = useState("mixed");
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    fetchData();
  }, [props.key, params]);

  const fetchData = () => {
    setLoading(true);
    let order = params && params !== "mixed" && `fixed_amount ${params}`;
    let group = true;
    // let rates = Object.keys(filter.rate).filter((key) => {
    //   if (filter.rate[key]) return key;
    // });
    // let dis = Object.keys(filter.discount).filter((key) => {
    //   if (filter.discount[key]) return key;
    // });
    // let amount = filter?.amount?.split(" ");
    // let r = {
    //   start_rating: rates?.[rates.length - 1] ? rates?.[0] : 0,
    //   end_rating: rates?.[rates.length - 1] || rates?.[0],
    //   start_rate: amount?.[0],
    //   end_rate: amount?.[2],
    //   start_per: dis?.[0],
    //   end_per: dis?.[rates - 1],
    // };
    // console.log(r, "oo");
    // console.log(rates, dis, amount, r,rates?.[rates - 1], "oooo");
    fetchProducts({ type: props.key, order, group, page: 150 })
      .then(({ data }) => {
        let datas = data.filter((arr) => {
          if (arr?.id) {
            return arr;
          }
        });
        const groupBy = datas.reduce((group, product) => {
          const { product_detail } = product;
          group[product_detail?.screen_size] =
            group[product_detail?.screen_size] ?? [];
          group[product_detail?.screen_size].push(product);
          return group;
        }, {});
        setProductData(groupBy);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        handleToast(api, err);
        console.log(err);
      });
  };

  const handleRedirect = (id) => {
    window.location.pathname = `/product/${id}/details`;
  };

  const handleOnCard = (row) => {
    setModel(true);
    setCardData(row);
  };

  const handleFilter = (e, key) => {
    const { name, checked, value } = e.target;
    if (value) {
      let r = { ...filter };
      r[name] = value;
      setFilter(r);
    } else {
      let r = { ...filter };
      r[key][name] = checked;
      setFilter(r);
    }
    fetchData();
  };

  const handleClose = () => {
    setModel(false);
    setCardData({});
    setFilterModel(false);
  };

  const onChange = (key) => {
    // setLoading(false);
    setParams(key);
    fetchData();
  };

  if (loading) {
    return <Loader />;
  }

  const items = [
    {
      key: "mixed",
      label: "Mixed price",
      children: (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(productData)?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    loading="lazy"
                    src={noData}
                    alt=""
                    style={{ width: "70%" }}
                  />
                </div>
              ) : (
                Object?.keys(productData).map((key) => {
                  if (key) {
                    return (
                      <>
                        <div
                          className="container"
                          style={{
                            margin: "0px auto",
                            // ,
                            display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h2 style={{ color: "black" }}>{key} Inches</h2>
                          <p
                            style={{
                              margin: 0,
                              borderBottom: "2px solid black",
                              width: "100%",
                              // color: "#black",
                            }}
                          ></p>
                        </div>
                        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
                          {productData[key]?.length > 0 &&
                            productData[key].map((arr) => {
                              return (
                                <Col {...{ xs: 24, sm: 12, md: 6 }}>
                                  <Card
                                    id="proCard"
                                    cover={
                                      <img
                                        loading="lazy"
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                        onClick={() => handleRedirect(arr.id)}
                                        alt="example"
                                        src={
                                          arr?.title_image_path?.startsWith(
                                            "https"
                                          )
                                            ? arr?.title_image_path
                                            : arr.title_image_path
                                            ? `${apiHost}/${arr.title_image_path}`
                                            : dummytv
                                        }
                                      />
                                    }
                                    actions={[
                                      <Tooltip title="">
                                        <BsArrowsFullscreen
                                          onClick={() => handleOnCard(arr)}
                                          style={{ fontSize: 18 }}
                                        />
                                      </Tooltip>,
                                      // <BsCart style={{ fontSize: 18 }} />,
                                    ]}
                                  >
                                    <Meta
                                      title={arr.title}
                                      description={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 20,
                                          }}
                                        >
                                          <h3
                                            style={{ fontSize: "1rem" }}
                                          >{`₹ ${arr.discount_amount}`}</h3>
                                          <strike style={{ fontSize: "1rem" }}>
                                            {`₹ ${arr.fixed_amount}`}
                                          </strike>
                                          {arr.offer_percentage !== "NA" && (
                                            <p
                                              style={{ fontSize: "1rem" }}
                                            >{`${arr.offer_percentage}% off`}</p>
                                          )}
                                        </div>
                                        // arr?.product_detail?.description
                                        //   ? sliceContent(arr?.product_detail?.description, 30)
                                        //   : "-"
                                      }
                                    />
                                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={cardData.overall_ratings}
                      />
                    </div> */}
                                  </Card>
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    );
                  }
                })
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "asc",
      label: "Price low to high",
      children: (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(productData)?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    loading="lazy"
                    src={noData}
                    alt=""
                    style={{ width: "70%" }}
                  />
                </div>
              ) : (
                Object?.keys(productData).map((key) => {
                  if (key) {
                    return (
                      <>
                        <div
                          className="container"
                          style={{
                            margin: "0px auto",
                            // textAlign: "center",
                            display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h2 style={{ color: "black" }}>{key} Inches</h2>
                          <p
                            style={{
                              margin: 0,
                              borderBottom: "2px solid black",
                              width: "100%",
                              // color: "#black",
                            }}
                          ></p>
                        </div>
                        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
                          {productData[key]?.length > 0 &&
                            productData[key].map((arr) => {
                              return (
                                <Col {...{ xs: 24, sm: 12, md: 6 }}>
                                  <Card
                                    id="proCard"
                                    cover={
                                      <img
                                        loading="lazy"
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                        onClick={() => handleRedirect(arr.id)}
                                        alt="example"
                                        src={
                                          arr?.title_image_path?.startsWith(
                                            "https"
                                          )
                                            ? arr?.title_image_path
                                            : arr.title_image_path
                                            ? `${apiHost}/${arr.title_image_path}`
                                            : dummytv
                                        }
                                      />
                                    }
                                    actions={[
                                      <Tooltip title="">
                                        <BsArrowsFullscreen
                                          onClick={() => handleOnCard(arr)}
                                          style={{ fontSize: 18 }}
                                        />
                                      </Tooltip>,
                                      // <BsCart style={{ fontSize: 18 }} />,
                                    ]}
                                  >
                                    <Meta
                                      title={arr.title}
                                      description={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 20,
                                          }}
                                        >
                                          <h3
                                            style={{ fontSize: "1rem" }}
                                          >{`₹ ${arr.discount_amount}`}</h3>
                                          <strike style={{ fontSize: "1rem" }}>
                                            {`₹ ${arr.fixed_amount}`}
                                          </strike>
                                          {arr.offer_percentage !== "NA" && (
                                            <p
                                              style={{ fontSize: "1rem" }}
                                            >{`${arr.offer_percentage}% off`}</p>
                                          )}
                                        </div>
                                        // arr?.product_detail?.description
                                        //   ? sliceContent(arr?.product_detail?.description, 30)
                                        //   : "-"
                                      }
                                    />
                                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={cardData.overall_ratings}
                      />
                    </div> */}
                                  </Card>
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    );
                  }
                })
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "desc",
      label: "Price high to low",
      children: (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(productData)?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    loading="lazy"
                    src={noData}
                    alt=""
                    style={{ width: "70%" }}
                  />
                </div>
              ) : (
                Object?.keys(productData).map((key) => {
                  if (key) {
                    return (
                      <>
                        <div
                          className="container"
                          style={{
                            margin: "0px auto",
                            // textAlign: "center",
                            display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h2 style={{ color: "black" }}>{key} Inches</h2>
                          <p
                            style={{
                              margin: 0,
                              borderBottom: "2px solid black",
                              width: "100%",
                              // color: "#black",
                            }}
                          ></p>
                        </div>
                        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
                          {productData[key]?.length > 0 &&
                            productData[key].map((arr) => {
                              return (
                                <Col {...{ xs: 24, sm: 12, md: 6 }}>
                                  <Card
                                    id="proCard"
                                    cover={
                                      <img
                                        loading="lazy"
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                        onClick={() => handleRedirect(arr.id)}
                                        alt="example"
                                        src={
                                          arr?.title_image_path?.startsWith(
                                            "https"
                                          )
                                            ? arr?.title_image_path
                                            : arr.title_image_path
                                            ? `${apiHost}/${arr.title_image_path}`
                                            : dummytv
                                        }
                                      />
                                    }
                                    actions={[
                                      <Tooltip title="">
                                        <BsArrowsFullscreen
                                          onClick={() => handleOnCard(arr)}
                                          style={{ fontSize: 18 }}
                                        />
                                      </Tooltip>,
                                      // <BsCart style={{ fontSize: 18 }} />,
                                    ]}
                                  >
                                    <Meta
                                      title={arr.title}
                                      description={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 20,
                                          }}
                                        >
                                          <h3
                                            style={{ fontSize: "1rem" }}
                                          >{`₹ ${arr.discount_amount}`}</h3>
                                          <strike style={{ fontSize: "1rem" }}>
                                            {`₹ ${arr.fixed_amount}`}
                                          </strike>
                                          {arr.offer_percentage !== "NA" && (
                                            <p
                                              style={{ fontSize: "1rem" }}
                                            >{`${arr.offer_percentage}% off`}</p>
                                          )}
                                        </div>
                                        // arr?.product_detail?.description
                                        //   ? sliceContent(arr?.product_detail?.description, 30)
                                        //   : "-"
                                      }
                                    />
                                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={cardData.overall_ratings}
                      />
                    </div> */}
                                  </Card>
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    );
                  }
                })
              )}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <AppBar />
      <div className="container" style={{ margin: "22px auto" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 16,
                  alignItems: "center",
                }}
              >
                <FiFilter
                  onClick={() => setFilterModel(true)}
                  style={{ fontSize: "1.4rem", cursor: "pointer" }}
                />
              </div> */}
              {Object.keys(productData)?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    loading="lazy"
                    src={noData}
                    alt=""
                    style={{ width: "70%" }}
                  />
                </div>
              ) : (
                <Tabs
                  defaultActiveKey={params}
                  items={items}
                  onChange={onChange}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Drawer
          title="Filter"
          placement="right"
          onClose={() => handleClose()}
          open={filterModel}
        >
          {/* <div>
            <h2 style={{ fontSize: "1.4rem" }}>RATINGS</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: 18 }}>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "rate")}
                name={4}
                checked={filter.rate[4]}
              >
                4 <AiFillStar />
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "rate")}
                name={3}
                checked={filter.rate[3]}
              >
                3 <AiFillStar />
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "rate")}
                name={2}
                checked={filter.rate[2]}
              >
                2 <AiFillStar />
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "rate")}
                name={1}
                checked={filter.rate[1]}
              >
                1 <AiFillStar />
              </Checkbox>
            </div>
          </div> */}
          <div>
            <h2 style={{ fontSize: "1.4rem" }}>DISCOUNT</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: 18 }}>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "discount")}
                name={50}
                checked={filter.discount[50]}
              >
                50%
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "discount")}
                name={40}
                checked={filter.discount[40]}
              >
                40%
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "discount")}
                name={30}
                checked={filter.discount[30]}
              >
                30%
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "discount")}
                name={20}
                checked={filter.discount[20]}
              >
                20%
              </Checkbox>
              <Checkbox
                style={{ fontSize: "1rem" }}
                onChange={(e) => handleFilter(e, "discount")}
                name={10}
                checked={filter.discount[10]}
              >
                10%
              </Checkbox>
            </div>
          </div>
          <div>
            <h2 style={{ fontSize: "1.4rem" }}>PRICE</h2>
            <Radio.Group
              onChange={(e) => handleFilter(e, "amount")}
              value={filter.amount}
              name={"amount"}
              style={{ display: "flex", flexDirection: "column", gap: 18 }}
            >
              <Radio
                style={{ fontSize: "1rem" }}
                // onChange={(e) => handleFilter(e, "amount")}
                value={"0.00 - 11,750.00"}
              >
                ₹ 0.00 - ₹ 11,750.00
              </Radio>
              <Radio
                style={{ fontSize: "1rem" }}
                // onChange={(e) => handleFilter(e, "amount")}
                value={"11,750.00 - 23,500.00"}
              >
                ₹ 11,750.00 - ₹ 23,500.00
              </Radio>
              <Radio
                style={{ fontSize: "1rem" }}
                // onChange={(e) => handleFilter(e, "amount")}
                value={"23,500.00 -  35,250.00"}
              >
                ₹ 23,500.00 - ₹ 35,250.00
              </Radio>
              <Radio
                style={{ fontSize: "1rem" }}
                // onChange={(e) => handleFilter(e, "amount")}
                value={"35,250.00 - 100000000000"}
              >
                ₹ 35,250.00 +
              </Radio>
            </Radio.Group>
          </div>
        </Drawer>
        <Drawer
          title="Details"
          placement="right"
          onClose={() => handleClose()}
          open={model}
          width={820}
        >
          <div>
            {cardData?.product_images?.length > 0 && (
              <CustomCarousel
                autoplay
                data={[
                  { image_path: cardData.title_image_path },
                  ...cardData.product_images,
                ]}
              />
            )}
          </div>
          <div>
            <h3>{cardData?.title}</h3>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              {/* <Rate
                disabled
                allowHalf
                defaultValue={cardData.overall_ratings}
              /> */}
              {/* <p>{cardData?.overall_ratings || 0} Ratings</p> */}
              {/* <p>40 Reviews</p> */}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <h3>{`₹ ${cardData.discount_amount}`}</h3>
              <strike>{`₹ ${cardData.fixed_amount}`}</strike>
              <p>{`${cardData.offer_percentage}% off`}</p>
            </div>
          </div>
          <table>
            <tbody style={{ lineHeight: "35px" }}>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Screen Size
                </td>
                <td
                  style={{ fontSize: 18 }}
                >{`${cardData?.product_detail?.screen_size}`}</td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Supported Internet Services
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.supported_internet}
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Display Technology
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.display_technology}
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Special Feature
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.spl_feature}
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Model Name
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.modle}
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Resolution
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.resolution}
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Refresh Rate
                </td>
                <td style={{ fontSize: 18,  }}>
                  {cardData?.product_detail?.refresh_rate} Hz
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Included Components
                </td>
                <td style={{ fontSize: 18,  }}>
                  {cardData?.product_detail?.components}
                </td>
              </tr> */}
              <tr>
                <td
                  style={{ width: "50%", fontWeight: "bolder", fontSize: 20 }}
                >
                  Connectivity Technology
                </td>
                <td style={{ fontSize: 18 }}>
                  {cardData?.product_detail?.technology}
                </td>
              </tr>
            </tbody>
          </table>
        </Drawer>
      </div>
    </>
  );
};
