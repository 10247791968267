import React, { useState, useEffect } from "react";
import { Card, Tooltip, Row, Col, Rate, Checkbox, Tabs, Drawer } from "antd";
import { AppBar } from "components/AppBar";
import { BsArrowsFullscreen, BsCart } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
// import { FiFilter } from "react-icons/fi";
import { fetchLikeProducts } from "api/product";
import dummytv from "asserts/img/dummy-log.png";
import { CustomCarousel } from "components/Carousel";
import { apiHost } from "config";
import { notification } from "antd";
import { handleToast } from "components/toast";
import { Loader } from "components/loader";
import noData from "asserts/img/noData.jpg";

const { Meta } = Card;

let norStyle = {};

// let lineStyle = {
//   display: "flex",
// };

export const LikePage = ({ props }) => {
  // const [grid, setGrid] = useState(false);
  const [model, setModel] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [cardData, setCardData] = useState({});
  const [productData, setProductData] = useState();
  const [params, setParams] = useState();
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    fetchData();
  }, [props?.key, params]);

  const fetchData = () => {
    let paramss = params && params !== "mixed" && `fixed_amount ${params}`;
    fetchLikeProducts({ type: props?.key, paramss })
      .then(({ data }) => {
        setLoading(false);
        setProductData(data);
      })
      .catch((err) => {
        console.log(err);
        if (err[0] === 401) {
          window.location.pathname = "/login";
        }
        handleToast(api, err);
      });
  };

  let normal = { xs: 24, sm: 12, md: 6 };
  // let oneLine = { xs: 24 };
  let gridSize = normal;
  let styles = norStyle;

  const handleRedirect = (id) => {
    window.location.pathname = `/product/${id}/details`;
  };

  const handleOnCard = (row) => {
    setModel(true);
    setCardData(row);
  };

  const handleFilter = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked);
    // setFilterModel(true);
    // setCardData(row);
  };

  const handleClose = () => {
    setModel(false);
    setCardData({});
    setFilterModel(false);
  };

  const onChange = (key) => {
    setParams(key);
  };

  const items = [
    {
      key: "mixed",
      label: "Mixed price",
      children: (
        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
          {productData &&
            productData.map((arr, i) => (
              <Col {...gridSize}>
                <Card
                  style={{ ...styles }}
                  id="proCard"
                  cover={
                    <img
                      loading="lazy"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={() => handleRedirect(arr.id)}
                      alt="example"
                      src={
                        arr?.title_image_path?.startsWith("https")
                          ? arr?.title_image_path
                          : arr.title_image_path
                          ? `${apiHost}/${arr.title_image_path}`
                          : dummytv
                      }
                    />
                  }
                  actions={[
                    <Tooltip title="">
                      <BsArrowsFullscreen
                        onClick={() => handleOnCard(arr)}
                        style={{ fontSize: 18 }}
                      />
                    </Tooltip>,
                    // <BsCart style={{ fontSize: 18 }} />,
                    // <BsPalette2 style={{ fontSize: 18 }} />,
                  ]}
                >
                  <Meta title={arr.title} />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={cardData.overall_ratings}
                    />
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      ),
    },
    {
      key: "asc",
      label: "Price high to low",
      children: (
        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
          {productData &&
            productData.map((arr, i) => (
              <Col {...gridSize}>
                <Card
                  style={{ ...styles }}
                  id="proCard"
                  cover={
                    <img
                      loading="lazy"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={() => handleRedirect(arr.id)}
                      alt="example"
                      src={
                        arr?.title_image_path?.startsWith("https")
                          ? arr?.title_image_path
                          : arr.title_image_path
                          ? `${apiHost}/${arr.title_image_path}`
                          : dummytv
                      }
                    />
                  }
                  actions={[
                    <Tooltip title="">
                      <BsArrowsFullscreen
                        onClick={() => handleOnCard(arr)}
                        style={{ fontSize: 18 }}
                      />
                    </Tooltip>,
                    // <BsCart style={{ fontSize: 18 }} />,
                    // <BsPalette2 style={{ fontSize: 18 }} />,
                  ]}
                >
                  <Meta title={arr.title} />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={cardData.overall_ratings}
                    />
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      ),
    },
    {
      key: "desc",
      label: "Price low to high",
      children: (
        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
          {productData &&
            productData.map((arr, i) => (
              <Col {...gridSize}>
                <Card
                  style={{ ...styles }}
                  id="proCard"
                  cover={
                    <img
                      loading="lazy"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={() => handleRedirect(arr.id)}
                      alt="example"
                      src={
                        arr?.title_image_path?.startsWith("https")
                          ? arr?.title_image_path
                          : arr.title_image_path
                          ? `${apiHost}/${arr.title_image_path}`
                          : dummytv
                      }
                    />
                  }
                  actions={[
                    <Tooltip title="">
                      <BsArrowsFullscreen
                        onClick={() => handleOnCard(arr)}
                        style={{ fontSize: 18 }}
                      />
                    </Tooltip>,
                    // <BsCart style={{ fontSize: 18 }} />,
                    // <BsPalette2 style={{ fontSize: 18 }} />,
                  ]}
                >
                  <Meta title={arr.title} />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={cardData.overall_ratings}
                    />
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      ),
    },
  ];

  if (productData) {
    return (
      <>
        {contextHolder}
        <AppBar />
        <div className="container" style={{ margin: "22px auto" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 16,
                    alignItems: "center",
                  }}
                >
                  {/* <BsListUl
                    onClick={() => setGrid(true)}
                    style={{ fontSize: 26 }}
                  />
                  <BsGrid3X3GapFill
                    onClick={() => setGrid(false)}
                    style={{ fontSize: 20 }}
                  /> */}
                  {/* <FiFilter
                    onClick={() => setFilterModel(true)}
                    style={{ fontSize: "1.4rem" }}
                  /> */}
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {productData.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          loading="lazy"
                          src={noData}
                          alt=""
                          style={{ width: "70%" }}
                        />
                      </div>
                    ) : (
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                      />
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Drawer
            title="Filter"
            placement="right"
            onClose={() => handleClose()}
            open={filterModel}
          >
            <div>
              <h2 style={{ fontSize: "1.4rem" }}>RATINGS</h2>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 18 }}
              >
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  4 <AiFillStar />
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  3 <AiFillStar />
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  2 <AiFillStar />
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  1 <AiFillStar />
                </Checkbox>
              </div>
            </div>
            <div>
              <h2 style={{ fontSize: "1.4rem" }}>DISCOUNT</h2>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 18 }}
              >
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  50%
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  40%
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  30%
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  20%
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  10%
                </Checkbox>
              </div>
            </div>
            <div>
              <h2 style={{ fontSize: "1.4rem" }}>PRICE</h2>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 18 }}
              >
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  ₹0.00 - ₹11,750.00
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  ₹11,750.00 - ₹23,500.00
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  ₹23,500.00 - ₹35,250.00
                </Checkbox>
                <Checkbox
                  style={{ fontSize: "1rem" }}
                  onChange={handleFilter}
                  name="rate"
                >
                  ₹35,250.00 +
                </Checkbox>
              </div>
            </div>
          </Drawer>
          <Drawer
            title="Details"
            placement="right"
            onClose={() => handleClose()}
            open={model}
            width={820}
          >
            <div>
              {cardData?.product_images?.length > 0 && (
                <CustomCarousel
                  autoplay
                  data={[
                    { image_path: cardData.title_image_path },
                    ...cardData.product_images,
                  ]}
                />
              )}
            </div>
            <div>
              <h3>{cardData?.title}</h3>
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <Rate
                  disabled
                  allowHalf
                  defaultValue={cardData.overall_ratings}
                />
                <p>430 Ratings</p>
                <p>40 Reviews</p>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <h3>{`₹${cardData.discount_amount}`}</h3>
                <strike>{`₹${cardData.fixed_amount}`}</strike>
                <p>{`${cardData.offer_percentage}% off`}</p>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Screen Size
                  </td>
                  <td>{`${cardData?.product_detail?.screen_size} Inches`}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Supported Internet Services
                  </td>
                  <td>{cardData?.product_detail?.supported_internet}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Display Technology
                  </td>
                  <td>{cardData?.product_detail?.display_technology}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Special Feature
                  </td>
                  <td>{cardData?.product_detail?.spl_feature}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Model Name
                  </td>
                  <td>{cardData?.product_detail?.modle}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Resolution
                  </td>
                  <td>{cardData?.product_detail?.resolution}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Refresh Rate
                  </td>
                  <td>{cardData?.product_detail?.refresh_rate} Hz</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Included Components
                  </td>
                  <td>{cardData?.product_detail?.components}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%", fontWeight: "bolder" }}>
                    Connectivity Technology
                  </td>
                  <td>{cardData?.product_detail?.technology}</td>
                </tr>
              </tbody>
            </table>
          </Drawer>
        </div>
      </>
    );
  }

  return <></>;
};
