import { MainPage } from "views/MainPage";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { DetailsPage } from "views/DetailsPage";
import { ProductPage } from "views/productPage";
import { LikePage } from "views/LikePage";
// import { CartPage } from "views/CartPage";
import { LoginPage } from "views/Auth/login";
// import { ForgotPage } from "views/Auth/forgot";
import { SignupPage } from "views/Auth/signup";
// import { siteName } from "config";
// import { getCookie } from "helpers";
import { ContactPage } from "views/contact";
import { AboutPage } from "views/about";
import { PrivacyPage } from "views/privacy";
import { DamagePage } from "views/damage";
import { CancelationPage } from "views/cancelation";
import { RefundPage } from "views/refund";
import { TermsPage } from "views/terms";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  // const isAuthenticated = getCookie(`${siteName}_token`);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/product/:id/details" element={<DetailsPage />} />
        {/* <Route path="/product/likes" element={<LikePage />} /> */}
        <Route path="/product/carts" element={<LikePage />} />
        <Route
          path="/products"
          element={<ProductPage props={{ key: "normal" }} />}
        />
        <Route
          path="/featured/products"
          element={<ProductPage props={{ key: "feature" }} />}
        />
        <Route
          path="/trending/products"
          element={<ProductPage props={{ key: "trending" }} />}
        />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/damage-policy" element={<DamagePage />} />
        <Route path="/cancelation-policy" element={<CancelationPage />} />
        <Route path="/refund" element={<RefundPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign" element={<SignupPage />} />

        <Route path="*" element={<>no</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
