import { siteName } from "config";
import moment from "moment";

export function getCookie(name) {
  let cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie && cookie[2];
}

export function setCookie(name, value, days = 0, hours = 0) {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value + ";path=/";
  }
}

export const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export function deleteAllCookie() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export async function kickUser() {
  await deleteAllCookie();
  await window.localStorage.clear();
  if (window.location.pathname !== "/login") {
    await window.location.reload();
  }
}

export const headers = (formData) => {
  if (formData === true) {
    return {
      Authorization: getCookie(`${siteName}_token`),
    };
  } else {
    return {
      ...jsonHeaders,
      Authorization: getCookie(`${siteName}_token`),
    };
  }
};

export function formatTime(time, format) {
  if (!time) return "-";

  format = format || "MMM-DD HH:mm:ss";
  return moment(time).format(format);
}

export function sliceContent(str, size) {
  if (str) {
    let len = str.length || 200;
    if (len > size) {
      return str.slice(0, size) + "...";
    } else {
      return str;
    }
  } else {
    return str;
  }
}

export function delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
