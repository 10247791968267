import React, { useEffect, useState } from "react";
import { AppBar } from "components/AppBar";
import { CustomCarousel } from "components/Carousel";
import { Card, Tooltip, Row, Col, Drawer, Button } from "antd";
import {
  BsArrowsFullscreen,
  BsCart,
  // BsPalette2
} from "react-icons/bs";
import { Footer } from "components/Footer";
import { fetchBannerIamges, fetchProducts } from "api/product";
// import { sliceContent } from "helpers";
import dummytv from "asserts/img/dummy-log.png";
import NotFound from "asserts/img/notFound.png";
import { apiHost } from "config";
import { Loader } from "components/loader";
import commingSoon from "asserts/img/cs.jpg";
// import { AiFillRightCircle, AiFillLeftCircle } from "react-icons/ai";

const { Meta } = Card;

export const MainPage = () => {
  const [imagesData, setImagesData] = useState();
  const [trendingData, setTrendingData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [proData, setProData] = useState([]);
  const [loder, setLoader] = useState(true);
  const [model, setModel] = useState(false);
  const [cardData, setCardData] = useState({});
  const [erroPage, setErroPage] = useState(false);
  // const history = useNavigate();

  useEffect(() => {
    fetchIamges();
    fetchData();
  }, []);

  const fetchIamges = () => {
    fetchBannerIamges()
      .then(({ data }) => {
        let img =
          data.length > 0
            ? data
            : {
                likes_count: data.likes_count,
                banner_images: [...data.banner_images],
              };
        setImagesData(img);
      })
      .catch((err) => {
        // setErroPage(true);
        console.log(err);
      });
  };

  const fetchData = () => {
    fetchProducts({ type: "normal" })
      .then(({ data }) => {
        setProData(
          data.filter((arr) => {
            if (arr?.fixed_amount !== 0) return arr;
          })
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
    fetchProducts({ type: "trending" })
      .then(({ data }) => {
        setTrendingData(
          data.filter((arr) => {
            if (arr.fixed_amount !== 0) return arr;
          })
        );
      })
      .catch((err) => {
        setErroPage(true);
        console.log(err);
      });
    fetchProducts({ type: "feature" })
      .then(({ data }) => {
        setFeatureData(
          data.filter((arr) => {
            if (arr.fixed_amount !== 0) return arr;
          })
        );
        setLoader(false);
      })
      .catch((err) => {
        // setErroPage(true);
        console.log(err);
      });
  };

  const handleRedirect = (id) => {
    window.location.pathname = `/product/${id}/details`;
  };

  const handleOnCard = (row) => {
    setModel(true);
    setCardData(row);
  };

  // const handleOnLike = (row) => {
  //   createLike(row.id)
  //     .then(({ data }) => {})
  //     .catch((err) => {
  //       if (err[0] === 401) {
  //         return history("/login");
  //       }
  //       console.log(err);
  //     });
  // };

  const handleClose = () => {
    setModel(false);
    setCardData({});
  };

  if (!loder) {
    return (
      <div style={{ position: "relative" }}>
        <AppBar like={imagesData?.likes_count} />
        <div style={{ position: "relative", width: "100%" }}>
          <CustomCarousel
            autoplay
            data={imagesData?.banner_images}
            height="36rem"
            arrows={true}
          />
        </div>
        <Row
          className="container"
          style={{
            marginTop: "3rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          gutter={[16, 16]}
        >
          <Col
            className="container"
            style={{
              margin: "0px auto",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            xs={24}
          >
            <h2 style={{ color: "black" }}>PRODUCTS</h2>
            <p
              style={{
                margin: 0,
                borderBottom: "2px solid black",
                width: "50%",
                // color: "#black",
              }}
            ></p>
          </Col>
          {proData && proData.length !== 0 ? (
            proData?.map((arr, i) => {
              if (i < 8) {
                return (
                  <Col xs={24} sm={12} md={6}>
                    <Card
                      id="cardHover"
                      cover={
                        <div
                          style={{
                            height: "15rem",
                            textAlign: "center",
                          }}
                        >
                          <img
                            style={{ cursor: "pointer", height: "100%" }}
                            onClick={() => handleRedirect(arr.id)}
                            alt="example"
                            loading="lazy"
                            src={
                              arr?.title_image_path?.startsWith("https")
                                ? arr?.title_image_path
                                : arr.title_image_path
                                ? `${apiHost}/${arr.title_image_path}`
                                : dummytv
                            }
                          />
                        </div>
                      }
                      actions={[
                        <Tooltip title="">
                          <BsArrowsFullscreen
                            onClick={() => handleOnCard(arr)}
                            style={{ fontSize: 18 }}
                          />
                        </Tooltip>,
                        // <BsCart
                        //   onClick={() => handleOnLike(arr)}
                        //   style={{ fontSize: 18 }}
                        // />,
                        // <BsPalette2 style={{ fontSize: 18 }} />,
                      ]}
                    >
                      <Meta
                        title={arr.title}
                        description={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <h3
                              style={{ fontSize: "1rem" }}
                            >{`₹ ${arr.discount_amount}`}</h3>
                            <strike style={{ fontSize: "1rem" }}>
                              {`₹ ${arr.fixed_amount}`}
                            </strike>
                            {arr.offer_percentage !== "NA" && (
                              <p
                                style={{ fontSize: "1rem" }}
                              >{`${arr.offer_percentage}% off`}</p>
                            )}
                          </div>
                          // arr?.product_detail?.description
                          //   ? sliceContent(arr?.product_detail?.description, 30)
                          //   : "-"
                        }
                      />
                    </Card>
                  </Col>
                );
              }
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                loading="lazy"
                src={commingSoon}
                style={{ width: "50%" }}
                alt=""
              />
            </div>
          )}
        </Row>
        <div id="Featured" style={{ height: 87 }}></div>
        <p style={{ marginTop: "2rem" }}></p>
        <div
          className="container"
          style={{
            margin: "0px auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ color: "black" }}>FEATURED PRODUCTS</h2>
          <p
            style={{
              margin: 0,
              borderBottom: "2px solid black",
              width: "50%",
              // color: "#black",
            }}
          ></p>
        </div>
        <Row
          className="container"
          style={{
            marginTop: "3rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          gutter={[16, 16]}
        >
          {featureData && featureData.length !== 0 ? (
            featureData?.map((arr, i) => {
              if (i < 8) {
                return (
                  <Col xs={24} sm={12} md={6} data-aos="zoom-in">
                    <Card
                      id="cardHover"
                      cover={
                        <img
                          loading="lazy"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirect(arr.id)}
                          alt="example"
                          src={
                            arr?.title_image_path?.startsWith("https")
                              ? arr?.title_image_path
                              : arr.title_image_path
                              ? `${apiHost}/${arr.title_image_path}`
                              : dummytv
                          }
                        />
                      }
                      actions={[
                        <Tooltip title="">
                          <BsArrowsFullscreen
                            onClick={() => handleOnCard(arr)}
                            style={{ fontSize: 18 }}
                          />
                        </Tooltip>,
                        // <BsCart
                        //   onClick={() => handleOnLike(arr)}
                        //   style={{ fontSize: 18 }}
                        // />,
                        // <BsPalette2 style={{ fontSize: 18 }} />,
                      ]}
                    >
                      <Meta
                        title={arr.title}
                        description={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <h3
                              style={{ fontSize: "1rem" }}
                            >{`₹ ${arr.discount_amount}`}</h3>
                            <strike style={{ fontSize: "1rem" }}>
                              {`₹ ${arr.fixed_amount}`}
                            </strike>
                            {arr.offer_percentage !== "NA" && (
                              <p
                                style={{ fontSize: "1rem" }}
                              >{`${arr.offer_percentage}% off`}</p>
                            )}
                          </div>
                          //   sliceContent(
                          //   arr?.product_detail?.description,
                          //   30
                          // )
                        }
                      />
                    </Card>
                  </Col>
                );
              }
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                loading="lazy"
                src={commingSoon}
                style={{ width: "50%" }}
                alt=""
              />
            </div>
          )}
        </Row>
        <p style={{ marginTop: "2rem" }}></p>
        <div
          className="container"
          style={{
            margin: "0px auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div id="Trinding" style={{ height: 87 }}></div>

          <h2 style={{ color: "black" }}>TRENDING PRODUCTS</h2>
          <p
            style={{ margin: 0, borderBottom: "2px solid black", width: "50%" }}
          ></p>
        </div>
        <Row
          className="container"
          style={{
            marginTop: "3rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          gutter={[16, 16]}
        >
          {trendingData && trendingData.length > 0 ? (
            trendingData.map((arr, i) => {
              if (i < 8) {
                return (
                  <Col xs={24} sm={12} md={6} data-aos="zoom-in">
                    <Card
                      id="cardHover"
                      cover={
                        <img
                          loading="lazy"
                          style={{ cursor: "pointer" }}
                          onClick={handleRedirect}
                          alt="example"
                          src={
                            arr?.title_image_path?.startsWith("https")
                              ? arr?.title_image_path
                              : arr.title_image_path
                              ? `${apiHost}/${arr.title_image_path}`
                              : dummytv
                          }
                        />
                      }
                      actions={[
                        <Tooltip title="">
                          <BsArrowsFullscreen
                            onClick={() => handleOnCard(arr)}
                            style={{ fontSize: 18 }}
                          />
                        </Tooltip>,
                        <BsCart style={{ fontSize: 18 }} />,
                        // <BsPalette2 style={{ fontSize: 18 }} />,
                      ]}
                    >
                      <Meta
                        title={arr.title}
                        description={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <h3
                              style={{ fontSize: "1rem" }}
                            >{`₹ ${arr.discount_amount}`}</h3>
                            <strike style={{ fontSize: "1rem" }}>
                              {`₹ ${arr.fixed_amount}`}
                            </strike>
                            {arr.offer_percentage !== "NA" && (
                              <p
                                style={{ fontSize: "1rem" }}
                              >{`${arr.offer_percentage}% off`}</p>
                            )}
                          </div>
                          //   sliceContent(
                          //   arr?.product_detail?.description,
                          //   30
                          // )
                        }
                      />
                    </Card>
                  </Col>
                );
              }
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                loading="lazy"
                src={commingSoon}
                style={{ width: "50%" }}
                alt=""
              />
            </div>
          )}
        </Row>

        <Footer />
        <Drawer
          title="Details"
          placement="right"
          onClose={() => handleClose()}
          open={model}
          width={820}
        >
          <div>
            {cardData?.product_images?.length > 0 && (
              <CustomCarousel
                autoplay
                data={[
                  { image_path: cardData.title_image_path },
                  ...cardData.product_images,
                ]}
                height={"20rem"}
                arrows={true}
                arrowColor={"red"}
              />
            )}
          </div>
          <div>
            <h3 style={{ fontSize: "2rem" }}>{cardData?.title}</h3>
            {/* <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <Rate
                disabled
                allowHalf
                defaultValue={cardData.overall_ratings}
              />
              <p>430 Ratings</p>
              <p>40 Reviews</p>
            </div> */}
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <h3
                style={{ fontSize: "1.5rem" }}
              >{`₹ ${cardData.discount_amount}`}</h3>
              <strike style={{ fontSize: "1.3rem" }}>
                {`₹ ${cardData.fixed_amount}`}
              </strike>
              {cardData.offer_percentage !== "NA" && (
                <p
                  style={{ fontSize: "1.3rem" }}
                >{`${cardData.offer_percentage}% off`}</p>
              )}
            </div>
          </div>
          <table>
            <tbody style={{ lineHeight: "34px" }}>
              <tr>
                <td
                  style={{
                    fontSize: "1.2rem",
                    width: "50%",
                    fontWeight: "bolder",
                  }}
                >
                  Screen Size
                </td>
                <td
                  style={{ fontSize: "1rem" }}
                >{`${cardData?.product_detail?.screen_size} Inches`}</td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Supported Internet Services
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.supported_internet}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Display Technology
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.display_technology}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Special Feature
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.spl_feature}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Model Name
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.modle}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Resolution
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.resolution}
                </td>
              </tr>
              {/* {cardData?.product_detail?.refresh_rate && (
                <tr>
                  <td
                    style={{
                      width: "50%",
                      fontWeight: "bolder",
                      fontSize: "1.2rem",
                    }}
                  >
                    Refresh Rate
                  </td>
                  <td style={{ fontSize: "1rem" }}>
                    {cardData?.product_detail?.refresh_rate} Hz
                  </td>
                </tr>
              )}
              {cardData?.product_detail?.components && (
                <tr>
                  <td
                    style={{
                      width: "50%",
                      fontWeight: "bolder",
                      fontSize: "1.2rem",
                    }}
                  >
                    Included Components
                  </td>
                  <td style={{ fontSize: "1rem" }}>
                    {cardData?.product_detail?.components}
                  </td>
                </tr>
              )} */}
              <tr>
                <td
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                    fontSize: "1.2rem",
                  }}
                >
                  Connectivity Technology
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {cardData?.product_detail?.technology}
                </td>
              </tr>
            </tbody>
          </table>
        </Drawer>
      </div>
    );
  }

  if (erroPage) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <img loading="lazy" src={NotFound} style={{ width: "50%" }} alt="" />
        <Button onClick={() => window.location.reload()} type="primary">
          Retry
        </Button>
      </div>
    );
  }

  return <Loader />;
};
