import makeRequest from "api";
import { siteName } from "config";
import { getCookie, headers, jsonHeaders } from "helpers";
import { json } from "react-router-dom";

export function fetchProducts(type) {
  return makeRequest({
    uri: `/api/v1/products?product_type=${type || ""}`,
    header: { ...jsonHeaders },
  });
}

export function createLike(id) {
  return makeRequest({
    uri: `/v1/products/${id}/like`,
    method: "POST",
    header: headers,
  });
}

export function fetchBannerIamges() {
  return makeRequest({
    uri: `/v1/banner_images`,
    header: { ...jsonHeaders },
  });
}

export function createReviewLike(id, body) {
  return makeRequest({
    uri: `/v1/products/${id}/update_ratings`,
    method: "PUT",
    body: JSON.stringify(body),
    header: getCookie(`${siteName}_token`) ? {
      ...jsonHeaders,
      Authorization: "API_KEY " + getCookie(`${siteName}_token`),
    } : null,
  });
}

export function fetchReview(id) {
  return makeRequest({
    uri: `/v1/products/${id}/rating_list`,
    header: getCookie(`${siteName}_token`) ? {
      ...jsonHeaders,
      Authorization: "API_KEY " + getCookie(`${siteName}_token`),
    } : null,
  });
}

export function createReview(id, body) {
  return makeRequest({
    uri: `/v1/products/${id}/ratings`,
    method: "POST",
    body: JSON.stringify(body),
    header: getCookie(`${siteName}_token`) ? {
      ...jsonHeaders,
      Authorization: "API_KEY " + getCookie(`${siteName}_token`),
    } : null,
  });
}
