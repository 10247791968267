import React, { useState } from "react";
import { BsGrid1X2, BsHeadset, BsInfoCircle, BsList } from "react-icons/bs";
import { BiTrendingUp } from "react-icons/bi";
import {
  AiOutlineDesktop,
  AiOutlineClose,
  AiOutlineStar,
} from "react-icons/ai";
import { Affix, Divider } from "antd";
import { Link } from "react-router-dom";
import FullLogo from "asserts/img/fulllogo.svg";
import { LuUser2 } from "react-icons/lu";

export const AppBar = ({ like }) => {
  const [toggle, setToggle] = useState(false);

  let path = window.location.pathname;

  return (
    <Affix style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
          padding: 25,
          zIndex: 9999,
          backgroundColor: "#fff",
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "red",
            fontSize: 30,
            width: "50%",
          }}
        >
          <div>
            <img
              src={FullLogo}
              loading="lazy"
              style={{ width: "7rem" }}
              width={"10%"}
              alt="Logo"
            />
          </div>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="menu-container"
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/" ? 800 : 400,
            }}
          >
            <BsGrid1X2 style={{ fontSize: 24 }} />
            <div>Home</div>
          </Link>
          <Link
            to="/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/products" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/products" ? 800 : 400,
            }}
          >
            <AiOutlineDesktop style={{ fontSize: 24 }} />
            <div>Products</div>
          </Link>
          <Link
            to="/featured/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/featured/products" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/featured/products" ? 800 : 400,
            }}
          >
            <AiOutlineStar style={{ fontSize: 24 }} />
            <div>Featured</div>
          </Link>
          <Link
            to="/trending/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/trending/products" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/trending/products" ? 800 : 400,
            }}
          >
            <BiTrendingUp style={{ fontSize: 24 }} />
            <div>Trending</div>
          </Link>

          <Link
            to="/contact"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/our-story" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/our-story" ? 800 : 400,
            }}
          >
            <BsHeadset style={{ fontSize: 24 }} />
            <div>Contact us</div>
          </Link>
          <Link
            to="/about"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/about" ? "#db3b44" : "black",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/about" ? 800 : 400,
            }}
          >
            <BsInfoCircle style={{ fontSize: 24 }} />
            <div>About us</div>
          </Link>
        </div>
        <div className="min-menu" onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <AiOutlineClose style={{ fontSize: 24 }} />
          ) : (
            <BsList style={{ fontSize: 24 }} />
          )}
        </div>
      </div>
      {toggle && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            backgroundColor: "black",
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
          }}
          data-aos="fade-left"
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/" ? 800 : 400,
            }}
          >
            <BsGrid1X2 style={{ fontSize: 24 }} />
            <div>Home</div>
          </Link>
          <div style={{ width: "80%" }}>
            <Divider style={{ border: "1px solid #fff" }} />
          </div>

          <Link
            to="/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/products" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/products" ? 800 : 400,
            }}
          >
            <AiOutlineDesktop style={{ fontSize: 24 }} />
            <div>Products</div>
          </Link>
          <div style={{ width: "80%" }}>
            <Divider style={{ border: "1px solid #fff" }} />
          </div>
          <Link
            to="/featured/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/featured/products" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/featured/products" ? 800 : 400,
            }}
          >
            <AiOutlineStar style={{ fontSize: 24 }} />
            <div>Featured</div>
          </Link>
          <div style={{ width: "80%" }}>
            <Divider style={{ border: "1px solid #fff" }} />
          </div>
          <Link
            to="/trending/products"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/trending/products" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/trending/products" ? 800 : 400,
            }}
          >
            <BiTrendingUp style={{ fontSize: 24 }} />
            <div>Trending</div>
          </Link>
          <div style={{ width: "80%" }}>
            <Divider style={{ border: "1px solid #fff" }} />
          </div>

          <Link
            to="/contact"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/our-story" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/our-story" ? 800 : 400,
            }}
          >
            <BsHeadset style={{ fontSize: 24 }} />
            <div>Contact us</div>
          </Link>
          <div style={{ width: "80%" }}>
            <Divider style={{ border: "1px solid #fff" }} />
          </div>
          <Link
            to="/about"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/about" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/about" ? 800 : 400,
            }}
          >
            <BsInfoCircle style={{ fontSize: 24 }} />
            <div>About us</div>
          </Link>
          <Link
            to="/about"
            style={{
              textDecoration: "none",
              padding: "0 22px",
              color: path === "/about" ? "#db3b44" : "#fff",
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: path === "/about" ? 800 : 400,
            }}
          >
            <LuUser2 style={{ fontSize: 24 }} />
            <div>About us</div>
          </Link>
        </div>
      )}
    </Affix>
  );
};
