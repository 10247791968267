import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Rate,
  Collapse,
  Drawer,
  Button,
  Input,
} from "antd";
// import {
//   BsHandThumbsUp,
//   BsChatLeftDots,
//   BsExclamationOctagon,
// } from "react-icons/bs";
// import dummyProfile from "asserts/img/dummy.jpg";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";
import { fetchDetailsProducts } from "api/product";
import dummyCor from "asserts/img/dummy-cro.png";
import // BsCart,
// BsCart,
// BsPalette2,
// BsBook,
// BsGrid1X2,
// BsList,
"react-icons/bs";
import {
  // createLike,
  // createReviewLike,
  // fetchReview,
  createReview,
} from "api/like";
// import { useNavigate } from "react-router-dom";
import { apiHost } from "config";
import { notification } from "antd";
import { handleToast } from "components/toast";
import { Loader } from "components/loader";
import { Payment } from "./payment";
// import { formatTime, getCookie } from "helpers";
// import { siteName } from "config";
const { TextArea } = Input;

export const DetailsPage = () => {
  const [defaultValue, setDefaultValue] = useState(0);
  const [productData, setProductData] = useState();
  // const [reviewData, setReviewData] = useState();
  const [rateModel, setRateModel] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState();
  // const [userData, setUserData] = useState();
  // const [loading, setLoading] = useState(false);
  // const history = useNavigate();
  const [loder, setLoader] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setData(null);
    setRateModel(false);
  };

  const fetchData = () => {
    fetchDetailsProducts(window.location.pathname.split("/")[2])
      .then(({ data }) => {
        setProductData(data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        handleToast(api, err);
      });
    // fetchReview(window.location.pathname.split("/")[2])
    //   .then(({ data, current_resident_rating }) => {
    //     setReviewData(data);
    //     setUserData(current_resident_rating)
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     handleToast(api, err);
    //   });
  };

  // const handleOnLike = (row) => {
  //   createLike(row.id)
  //     .then(() => {})
  //     .catch((err) => {
  //       if (err[0] === 401) {
  //         return history("/login");
  //       }
  //       console.log(err);
  //     });
  // };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let r = { ...data };
    r[name] = value;
    setData(r);
  };

  // const onSubmitLike = (id, value) => {
  //   setLoading(true);
  //   createReviewLike(id, {
  //     rating_type: value,
  //   })
  //     .then(({ data }) => {
  //       setLoading(false);
  //       // setReviewData(data);
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err[0] === 401) {
  //         window.location.pathname = "/login";
  //       }
  //       handleToast(api, err);
  //     });
  // };

  const onSubmitReview = () => {
    createReview(window.location.pathname.split("/")[2], {
      ratings: data.rating,
      comment: data.description,
    })
      .then(({ data }) => {
        setData(null);
        setRateModel(false);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        if (err[0] === 401) {
          window.location.pathname = "/login";
        }
        handleToast(api, err);
      });
  };

  if (loder) {
    return <Loader />;
  }

  return (
    <>
      {contextHolder}
      <AppBar />
      <div className="container" style={{ margin: "0px auto" }}>
        <Card
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            margin: "12px 0",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Row gutter={[16, 16]}>
                <Col xs={4}>
                  {productData &&
                    productData?.product_images?.map((arr, i) => (
                      <div style={{ height: "6rem", margin: "12px 0px" }}>
                        <img
                          key={i}
                          loading="lazy"
                          width={"80%"}
                          height={"100%"}
                          // src={arr?.image_path || dummyCor}
                          src={
                            arr?.image_path?.startsWith("https")
                              ? arr?.image_path
                              : arr?.image_path
                              ? `${apiHost}/${arr.image_path}`
                              : dummyCor
                          }
                          alt=""
                          style={{
                            border:
                              defaultValue === i
                                ? "1px solid"
                                : "1px solid #00000040",
                            padding: 2,
                            borderRadius: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => setDefaultValue(i)}
                        />
                      </div>
                    ))}
                </Col>
                <Col xs={20}>
                  <div style={{ height: "36rem" }}>
                    <img
                      loading="lazy"
                      width={"100%"}
                      height={"100%"}
                      src={
                        productData?.product_images?.[
                          defaultValue
                        ]?.image_path?.startsWith("https")
                          ? productData?.product_images?.[defaultValue]
                              ?.image_path
                          : productData?.product_images?.[defaultValue]
                              ?.image_path
                          ? `${apiHost}/${productData?.product_images?.[defaultValue].image_path}`
                          : dummyCor
                      }
                      alt=""
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: "2rem" }}>
                    <Payment id={window.location.pathname.split("/")[2]} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: 2, right: 3 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 12,
                      cursor: "pointer",
                    }}
                  >
                    {/* <BsCart
                      onClick={() =>
                        handleOnLike(window.location.pathname.split("/")[2])
                      }
                      style={{ fontSize: 18 }}
                    /> */}
                    {/* <BsCart style={{ fontSize: 18 }} /> */}
                  </div>
                </div>
                <h3>{productData?.title}</h3>
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  {/* <Rate
                    disabled
                    allowHalf
                    defaultValue={productData?.overall_ratings}
                  />
                  <p>{productData?.ratings_count} Ratings</p>
                  <p>{productData?.reviews_count} Reviews</p> */}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  <h3>{`₹ ${productData?.discount_amount}`}</h3>
                  <strike>{`₹ ${productData?.fixed_amount}`}</strike>
                  <p>{`${productData?.offer_percentage} % off`}</p>
                </div>
              </div>
              <Divider />

              <table>
                <tbody style={{ lineHeight: "34px" }}>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Screen Size
                    </td>
                    <td
                      style={{ paddingLeft: 20 }}
                    >{`${productData?.product_detail?.screen_size} Inches`}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Supported Internet Services
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.supported_internet}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Display Technology
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.display_technology}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Special Feature
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.spl_feature}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Model Name
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.modle}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Resolution
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.resolution}
                    </td>
                  </tr>
                  {productData?.product_detail?.refresh_rate &&
                    productData?.product_detail?.refresh_rate !== "null" && (
                      <tr>
                        <td style={{ width: "30%", fontWeight: "bolder" }}>
                          Refresh Rate
                        </td>
                        <td style={{ paddingLeft: 20 }}>
                          {productData?.product_detail?.refresh_rate} Hz
                        </td>
                      </tr>
                    )}

                  {productData?.product_detail?.components &&
                    productData?.product_detail?.components !== "null" && (
                      <tr>
                        <td style={{ width: "30%", fontWeight: "bolder" }}>
                          Included Components
                        </td>
                        <td style={{ paddingLeft: 20 }}>
                          {productData?.product_detail?.components}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td style={{ width: "30%", fontWeight: "bolder" }}>
                      Connectivity Technology
                    </td>
                    <td style={{ paddingLeft: 20 }}>
                      {productData?.product_detail?.technology}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider />
              <div>
                <h2>Description</h2>
                <p>{productData?.product_detail?.description}</p>
              </div>
            </Col>
          </Row>
        </Card>
        {productData && productData?.product_specifications && (
          <Card
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              margin: "12px 0",
            }}
          >
            <h2>Specifications</h2>
            <Row gutter={[16, 16]}>
              {Object.keys(productData.product_specifications).map((key, i) => (
                <Col xs={24} id="custom-collapse" sm={12}>
                  <Collapse
                    // collapsible="disabled"
                    //   style={{ width: "40rem" }}
                    collapsible="disabled"
                    defaultActiveKey={["1"]}
                    items={[
                      {
                        key: "1",
                        label: <h3>{key}</h3>,
                        children: (
                          <Row
                            style={{
                              display: "flex",
                              gap: 24,
                              flexWrap: "wrap",
                            }}
                            gutter={[16, 16]}
                          >
                            {productData?.product_specifications?.[key].map(
                              (arr, index) => {
                                return (
                                  <Col
                                    xs={
                                      productData?.product_specifications?.[key]
                                        .length <= 4
                                        ? 24
                                        : 10
                                    }
                                    key={index}
                                  >
                                    <p
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: 18,
                                        margin: 0,
                                      }}
                                    >
                                      {arr.name}
                                    </p>
                                    <p></p>
                                    <p style={{ margin: 0 }}>{arr.value}</p>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        ),
                      },
                    ]}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        )}
        {/* <Button onClick={() => setRateModel(true)}>Add Rate</Button> */}
        {/* <Card
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            margin: "12px 0",
          }}
        >

          {getCookie(`${siteName}_token`) && userData && (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <img
                    src={dummyProfile}
                    width={40}
                    alt=""
                    style={{ borderRadius: "50%" }}
                  />
                  <p>{userData?.name}</p>
                </div>
                <Rate
                  allowHalf
                  disabled
                  defaultValue={ 
                    userData?.user_rating
                  }
                />
                <p>
                  Reviewed in{" "}
                  {formatTime(
                    userData?.user_updated_at,
                    "DD/MM/YYYY HH:mm"
                  )}
                </p>
                <p>{userData?.user_comment}</p>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 12,
                      alignItems: "center",
                    }}
                  >
                    <BsHandThumbsUp
                      onClick={() => onSubmitLike(arr.id, "like")}
                      style={{ fontSize: 20 }}
                    />
                    <p style={{ padding: 0, margin: 0 }}>{arr.likes_count}</p>
                  </div>
                  <BsChatLeftDots style={{ fontSize: 20 }} />
                        <span>12</span>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 12,
                      alignItems: "center",
                    }}
                  >
                    <BsExclamationOctagon
                      onClick={() => onSubmitLike(arr.id, "report")}
                      style={{ fontSize: 20 }}
                    />
                    <p style={{ padding: 0, margin: 0 }}>
                      {arr.report_count}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {reviewData?.length > 0 && (
            <>
              {reviewData?.map((arr, i) => {
                return (
                  <>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={dummyProfile}
                          width={40}
                          alt=""
                          style={{ borderRadius: "50%" }}
                        />
                        <p>{arr.name}</p>
                      </div>
                      <Rate allowHalf disabled defaultValue={arr.rating} />
                      <p>
                        Reviewed in{" "}
                        {formatTime(arr.updated_at, "DD/MM/YYYY HH:mm")}
                      </p>
                      <p>{arr.comment}</p>
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 12,
                            alignItems: "center",
                          }}
                        >
                          <BsHandThumbsUp
                            onClick={() => onSubmitLike(arr.id, "like")}
                            style={{ fontSize: 20 }}
                          />
                          <p style={{ padding: 0, margin: 0 }}>
                            {arr.likes_count}
                          </p>
                        </div>
                        <BsChatLeftDots style={{ fontSize: 20 }} />
                          <span>12</span>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 12,
                            alignItems: "center",
                          }}
                        >
                          <BsExclamationOctagon
                            onClick={() => onSubmitLike(arr.id, "report")}
                            style={{ fontSize: 20 }}
                          />
                          <p style={{ padding: 0, margin: 0 }}>
                            {arr.report_count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <Divider />
                  </>
                );
              })}
            </>
          )}
          )}
        </Card> */}
      </div>
      <Drawer
        title="Rate this product"
        placement="right"
        onClose={() => handleClose()}
        open={rateModel}
      >
        <div style={{ textAlign: "end" }}>
          <Button onClick={() => onSubmitReview()}>Save</Button>
        </div>
        <p></p>
        <p>Rating</p>
        <Rate
          allowHalf
          onChange={(e) =>
            handleOnChange({ target: { name: "rating", value: e } })
          }
          defaultValue={data?.rating}
        />
        <p>Description</p>
        <TextArea
          onChange={(e) =>
            handleOnChange({
              target: {
                name: "description",
                value: e.target.value,
              },
            })
          }
          style={{ width: "30rem" }}
          value={data?.description}
          autoSize={{
            minRows: 8,
          }}
        />
      </Drawer>
      <Footer />
    </>
  );
};
