import { apiHost } from "config";
import { jsonHeaders } from "helpers";

export default async function makeRequest({
  uri,
  method = "GET",
  body,
  header,
}) {
  console.log(header.Authorization, "header");
  return fetch(apiHost + uri, {
    method,
    body,
    headers: header ? header : jsonHeaders,
  }).then(async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (err) {
        return true;
      }
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}
