import makeRequest from "api";
import { jsonHeaders } from "helpers";

export function Login(body) {
  return makeRequest({
    uri: `/login`,
    method: "POST",
    body: JSON.stringify(body),
    header: { ...jsonHeaders },
  });
}

export function Signup(body) {
  return makeRequest({
    uri: `/register`,
    method: "POST",
    body: JSON.stringify(body),
    header: { ...jsonHeaders },
  });
}

export function VerifyOtp(body) {
  return makeRequest({
    uri: `/verify_otp`,
    method: "POST",
    body: JSON.stringify(body),
    header: { ...jsonHeaders },
  });
}

export function ForgotPass(body) {
  return makeRequest({
    uri: `/forgot-password`,
    method: "PUT",
    body: JSON.stringify(body),
    header: { ...jsonHeaders },
  });
}

export function ValidateOtp(body, header) {
  return makeRequest({
    uri: `/validate-otp`,
    method: "PUT",
    body: JSON.stringify(body),
    header: { ...jsonHeaders, ...{ Authorization: `base64 ${header}` } },
  });
}

export function RePassword(body, header) {
  return makeRequest({
    uri: `/reset-password`,
    method: "PUT",
    body: JSON.stringify(body),
    header: { ...jsonHeaders, ...{ Authorization: `API_KEY ${header}` } },
  });
}
