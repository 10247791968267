import React from "react";

export const handleToast = (api, err) => {
  const key = "updatable";
  const errs = err?.[1]?.data ? err?.[1]?.data[0] : err?.[1]?.message;
  switch (err[0]) {
    case 200:
      api.success({
        key,
        message: errs || "Successfully.!!!",
      });
      break;
    case 201:
      api.success({
        key,
        message: errs || "Successfully.!!!",
      });
      break;
    case 401:
      api.error({
        key,
        message: errs || "Access Denied.!!!",
      });
      break;
    case 403:
      api.error({
        key,
        message: errs || "Access Denied.!!!",
      });
      break;
    case 404:
      api.warning({
        key,
        message: errs || "Not Found.!!!",
      });
      break;
    case 422:
      api.warning({
        key,
        message: errs,
      });
      break;
    case 429:
      api.warning({
        key,
        message: errs || "To many requests...!!",
      });
      break;
    case 400:
      api.warning({
        key,
        message: errs || "Bad Request...!!",
      });
      break;
    case 500:
      api.error({
        key,
        message: errs || "Internal Server Error..!",
      });
      break;
    case 502:
      api.error({
        key,
        message: errs,
      });
      break;
    default:
      api.error({
        key,
        message: "Something is not right.!!",
      });
  }
};

export const Toast = (data) => {
  return <>{data}</>;
};
