import makeRequest from "api";
import { siteName } from "config";
import { getCookie, headers, jsonHeaders } from "helpers";

export function fetchProducts({ type, order, group }) {
  return makeRequest({
    uri: `/v1/products?product_type=${type || ""}&order=${order || ""}&product_group=${group || ""}`,
    header: { ...jsonHeaders },
  });
}

export function fetchDetailsProducts(id) {
  return makeRequest({
    uri: `/v1/products/${id}`,
    header: { ...jsonHeaders },
  });
}

export function fetchBannerIamges() {
  return makeRequest({
    uri: `/v1/banner_images`,
    header: { ...jsonHeaders },
  });
}

export function fetchLikeProducts() {
  return makeRequest({
    uri: `/v1/products/likes_list`,
    header: getCookie(`${siteName}_token`)
      ? {
          ...jsonHeaders,
          Authorization: "API_KEY " + getCookie(`${siteName}_token`),
        }
      : null,
  });
}
