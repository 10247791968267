import React from "react";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";

export const RefundPage = () => {
  return (
    <>
      <AppBar />
      <div
        style={{
          background: "#d3d3d3",
          color: "red",
          padding: "100px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <h1>Refund policy</h1>
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We don’t provide any refund on any of our products.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          We only provide replacement within 7 days of delivery if the product
          is found in broken state at the time of delivery.
        </p>

        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          For that also the customer has to provide us with box opening video
          and pictures of the broken product on the same day the product is
          delivered.
        </p>
      </div>
      <Footer />
    </>
  );
};
