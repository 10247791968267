import React, { useState } from "react";
import { Button, Card } from "antd";
import { CustomInput } from "components/fields";
import { Link } from "react-router-dom";
import { delete_cookie, getCookie, setCookie } from "helpers";
import { Signup, VerifyOtp } from "api/auth";
import { BsGrid1X2 } from "react-icons/bs";
import { siteName } from "config";
import { notification } from "antd";
import { handleToast } from "components/toast";

export const SignupPage = () => {
  const [data, setData] = useState();
  const [otpPage, setOtpPage] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const handleChange = (e) => {
    let datas = { ...data };
    const { name, value } = e.target;
    datas[name] = value;
    setData(datas);
  };

  const handleSubmit = () => {
    // if (!otpPage) {
    if (data?.password === data?.confirm_password) {
      Signup({ user: { ...data } })
        .then(({ data }) => {
          // setOtpPage(true);
          setCookie(`${siteName}_token`, data.auth_token);
          setCookie("name", data?.name || data.email);
          window.localStorage.setItem(`${siteName}_token`, data.auth_token);

          if (getCookie("pro_id")) {
            window.location.pathname = `product/${getCookie("pro_id")}/details`;
            delete_cookie("pro_id");
          } else {
            window.location.pathname = "/";
          }
        })
        .catch((err) => {
          console.log(err);
          handleToast(api, err);
        });
    }
    // } else {
    //   VerifyOtp({ username: data.email, otp_token: data.otp_token })
    //     .then(({ data }) => {
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       handleToast(api, err);
    //     });
    // }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      style={{
        height: "100vh",
        backgroundColor: "#ff000080",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {contextHolder}
      <Card
        style={{ textAlign: "center", width: 450, margin: 15, padding: 12 }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            gap: 8,
            color: "black",
          }}
        >
          <BsGrid1X2 style={{ fontSize: 18 }} />
          <div>Home</div>
        </Link>
        {!otpPage ? (
          <>
            <div
              style={{
                margin: "1rem 0",
                fontWeight: "bolder",
                fontSize: "25px",
              }}
            >
              Welcome to Viao
            </div>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Name *</p>
              <CustomInput
                onChange={handleChange}
                name="name"
                value={data?.name}
                required={true}
                placeholder="Enter your Name"
              />
            </div>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Phone *</p>
              <CustomInput
                onChange={handleChange}
                name="phone"
                value={data?.phone}
                required={true}
                placeholder="Enter your Phone"
              />
            </div>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Email *</p>
              <CustomInput
                onChange={handleChange}
                name="email"
                value={data?.email}
                required={true}
                placeholder="Enter your Email"
              />
            </div>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Password *</p>
              <CustomInput
                onChange={handleChange}
                name="password"
                value={data?.password}
                required={true}
                placeholder="Enter your password"
                type="password"
              />
            </div>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Confirm password *</p>
              <CustomInput
                onChange={handleChange}
                name="confirm_password"
                value={data?.confirm_password}
                required={true}
                placeholder="Enter your confirm password"
                type="password"
              />
            </div>
            <Button
              style={{
                margin: "1rem 0",
                width: "100%",
                background: "#ff000080",
                color: "#fff",
              }}
              htmlType="submit"
            >
              Signup
            </Button>
            <div style={{ margin: "1rem 32px" }}>
              Already have a account{" "}
              <Link
                to="/login"
                style={{ padding: 0, margin: 0, textDecoration: "none" }}
              >
                Login
              </Link>{" "}
              ?
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                margin: "1rem 0",
                fontWeight: "bolder",
                fontSize: "25px",
              }}
            >
              Welcome to Viao
            </div>
            <p></p>
            <div style={{ textAlign: "initial" }}>
              <p style={{ padding: 0, margin: 0 }}>Enter your OTP *</p>
              <CustomInput
                onChange={handleChange}
                name="otp_token"
                value={data?.otp_token}
                required={true}
                placeholder="Enter your OTP"
              />
            </div>
            <Button
              style={{
                margin: "1rem 0",
                width: "100%",
                background: "#ff000080",
                color: "#fff",
              }}
              htmlType="submit"
            >
              Verfiy
            </Button>
          </>
        )}
      </Card>
    </form>
  );
};
