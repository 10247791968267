import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsWhatsapp,
  BsGeoAlt,
} from "react-icons/bs";

export const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#0a0a0a",
        // height: "12rem",
        color: "#fff",
        marginTop: "2rem",
      }}
    >
      <div
        className="container"
        style={{ margin: "0 auto", paddingTop: "2rem" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: 20,
          }}
        >
          <div>
            <h3>Office Details:</h3>
            <div>
              <p style={{ color: "rgba(255,255,255,.6)" }}>
                215, Dr Rajendra Prasad Rd, Opp. Joy Alukkas, Tatabad
              </p>
              <p style={{ color: "rgba(255,255,255,.6)" }}>
                Gandhipuram, Coimbatore, Tamil Nadu 641012
              </p>
              <p style={{ color: "rgba(255,255,255,.6)" }}>+91 9842049982, +91 9514449981, +91 9514449982</p>
              <p style={{ color: "rgba(255,255,255,.6)" }}>viaoled@gmail.com</p>
            </div>
          </div>
          <div>
            <h3>Connect With</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <a
                href="https://maps.app.goo.gl/aKrJTeGXLH6cJNLG6"
                target="_blank"
                alt=""
                rel="noreferrer"
                id="link"
              >
                <BsGeoAlt style={{ fontSize: "1.5rem" }} />
              </a>
              <a
                href="https://www.facebook.com/vaio.ledtv?mibextid=ZbWKwL"
                target="_blank"
                alt=""
                rel="noreferrer"
                id="link"
              >
                <BsFacebook style={{ fontSize: 20 }} />
              </a>
              {/* <a href="/" target="_blank" alt="" rel="noreferrer" id="link">
                <BsTwitter style={{ fontSize: 20 }} />
              </a> */}
              <a
                href="https://instagram.com/viaoledtv?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
                target="_blank"
                alt=""
                rel="noreferrer"
                id="link"
              >
                <BsInstagram style={{ fontSize: 20 }} />
              </a>
            </div>
            <p></p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
                flexWrap: "wrap",
                cursor: "pointer",
              }}
              id="link"
            >
              <BsWhatsapp style={{ fontSize: 20 }} />
              <p style={{ margin: 0, padding: 0 }}>+91 9842049982</p>
            </div>
          </div>
          <div className="use-links">
            <h3>USEFULL LINKS</h3>
            <div>
              <a href="/">
                <p style={{ color: "rgba(255,255,255,.6)" }}>Home</p>
              </a>
              <a href="/terms">
                <p style={{ color: "rgba(255,255,255,.6)" }}>Terms & conditions</p>
              </a>
              <a href="/privacy">
                <p style={{ color: "rgba(255,255,255,.6)" }}>Privacy Policy</p>
              </a>
              <a href="/refund">
                <p style={{ color: "rgba(255,255,255,.6)" }}>Refund Policy</p>
              </a>
              <a href="/cancelation-policy">
                <p style={{ color: "rgba(255,255,255,.6)" }}>
                  Cancellation Policy
                </p>
              </a>
              <a href="/damage-policy">
                <p style={{ color: "rgba(255,255,255,.6)" }}>
                  Shipping & Damage Policy
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
