import React from "react";
import { AppBar } from "components/AppBar";
import { Footer } from "components/Footer";

export const TermsPage = () => {
  return (
    <>
      <AppBar />
      <div
        style={{
          background: "#d3d3d3",
          color: "red",
          padding: "100px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <h1>Terms & conditions</h1>
      </div>
      <div className="container" style={{ margin: "22px auto" }}>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Welcome to our website. If you continue to browse and use this
          website, you are agreeing to comply with and be bound by the following
          terms and conditions of use, which together with our privacy policy
          govern VIAO ENTERPRISES INDIA Pvt. Ltd.'s relationship with you in
          relation to this website.
        </p>
        <h1>Usage of this website is subject to the following terms of use:</h1>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          All trademarks reproduced in this website, which are not the property
          of, or licensed to the operator, are acknowledged on the website.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Unauthorized use of this website may give rise to a claim for damages
          and/or be a criminal offence.
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          From time to time, this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </p>
        <p style={{ fontSize: "2rem", lineHeight: "57px" }}>
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of INDIA.
        </p>
      </div>
      <Footer />
    </>
  );
};
