import React, { useState } from "react";
import { Button } from "antd";
import { apiHost } from "config";
import { confirmOrder, createOrder } from "api/payment";
import { handleToast } from "components/toast";
import { notification } from "antd";
import FullLogo from "asserts/img/fulllogo.svg";
import useRazorpay from "react-razorpay";
import { setCookie } from "helpers";

export const Payment = ({ id }) => {
  const [Razorpay, isLoaded] = useRazorpay();
  const [loder, setLoader] = useState(true);
  const [productData, setProductData] = useState();
  const [api, contextHolder] = notification.useNotification();

  const orderSubmit = () => {
    createOrder(id)
      .then(({ data }) => {
        // setProductData(data);
        // setLoader(false);

        try {
          const options = {
            key: "rzp_test_vlX2RpMEjYS8wI",
            amount: "3000",
            currency: "INR",
            name: "VIAO",
            description: "Transaction",
            image: { FullLogo },
            order_id: data.order_id,
            prefill: {
              name: data?.name,
              email: data?.email,
              contact: data?.phone,
            },
            handler: function (response) {
              console.log("Payment successful:", response);
              // router.push("/success")
              confirmPayment(response);
            },
            theme: {
              color: "#3399cc",
            },
            onpaymenterror: function (error) {
              console.log("Payment failed:", error.description);
              // window.location.pathname = "/error"
              handleToast(api, error.description);
            },
          };
          if (id) {
            const rzpay = new Razorpay(options);
            rzpay.open();
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.pathname = "/login";
        handleToast(api, err);
        setCookie("pro_id", id);
      });
  };

  const confirmPayment = (response) => {
    let r = {
      payment: {
        order_id: response.razorpay_order_id,
        payment_id: response.razorpay_payment_id,
        signature: response.razorpay_signature,
      },
    };
    confirmOrder(id, r)
      .then(({ data }) => {
        // setProductData(data);
        // setLoader(false);
        handleToast(api, [200, { message: "Payment successful" }]);
      })
      .catch((err) => {
        console.log(err);
        handleToast(api, err);
      });
  };

  return (
    <>
      {contextHolder}

      <Button
        onClick={() => orderSubmit()}
        style={{
          width: "100%",
          height: "3rem",
          fontSize: "20px",
          color: "red",
          borderColor: "red",
          fontWeight: 600,
        }}
      >
        Buy
      </Button>
    </>
  );
};
