import { Input } from "antd";

export const CustomInput = ({ value, required, ...rest }) => {
  return (
    <>
      <Input value={value} {...rest} />
      {required && (
        <input
          tabIndex={-1}
          autoComplete="on"
          style={{ opacity: 0, height: 0 }}
          value={value}
          required={true}
        />
      )}
    </>
  );
};

export const CustomInputPass = ({ required, value, ...rest }) => {
  return (
    <>
      <Input.Password {...rest} />
      {/* {required && ( */}
      <input
        tabIndex={-1}
        autoComplete="on"
        style={{ opacity: 0, height: 0 }}
        value={value}
        required={true}
      />
      {/* )} */}
    </>
  );
};
