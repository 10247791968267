import React from "react";
import { AppBar } from "components/AppBar";
import { handleToast } from "components/toast";
import {
  Card,
  // Tooltip,
  Row,
  Col,
  // Drawer,
  // Rate,
  // Checkbox,
  // Tabs,
  // Radio,
  Input,
  notification,
  Button,
} from "antd";
import { BsGeoAlt, BsWhatsapp, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { CustomInput } from "components/fields";
import { Footer } from "components/Footer";
const { TextArea } = Input;

export const ContactPage = () => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <AppBar />
      <div className="container" style={{ margin: "22px auto" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <h1>CONTACT US</h1>
            <Card>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={12}>
                  <p>Name</p>
                  <CustomInput />
                </Col>
                <Col xs={12}>
                  <p>Phone</p>
                  <CustomInput />
                </Col>
                <Col xs={24} style={{ marginTop: "0.5rem" }}>
                  <p>Email</p>
                  <CustomInput />
                </Col>
                <Col xs={24} style={{ marginTop: "0.5rem" }}>
                  <p>Description</p>

                  <TextArea
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                    // onChange={onChange}
                    // placeholder="disable resize"
                  />
                </Col>
                <Col style={{ marginTop: "1rem" }}>
                  <Button type="primary" size={"large"} htmlType="button">
                    Submit
                  </Button>
                </Col>
              </div>
            </Card>
            <p></p>
            <h1>Office Details</h1>
            <Card>
              <Row
                gutter={[16, 16]}
                style={{ display: "flex", gap: 20, flexWrap: "wrap" }}
              >
                <Col xs={12} sm={8}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <BsGeoAlt style={{ fontSize: "1.5rem" }} />
                    <h2>Address</h2>
                  </div>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    215, Dr Rajendra Prasad Rd, Opp. Joy Alukkas, Tatabad,
                    Gandhipuram,
                  </p>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    Coimbatore, Tamil Nadu 641012
                  </p>
                </Col>
                <Col xs={12} sm={4}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <BsTelephone style={{ fontSize: "1.5rem" }} />
                    <h2>Phone</h2>
                  </div>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    +91 9514449981
                  </p>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    +91 9842049982
                  </p>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    +91 9514449982
                  </p>
                </Col>
                <Col xs={12} sm={6}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 6 }}
                  >
                    <BsWhatsapp style={{ fontSize: "1.5rem" }} />
                    <h2>Whatsapp Number</h2>
                  </div>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    +91 9842049982
                  </p>
                </Col>
                <Col xs={12} sm={4}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <AiOutlineMail style={{ fontSize: "1.5rem" }} />
                    <h2>Email</h2>
                  </div>
                  <p style={{ fontSize: "1.2rem", padding: 0, margin: 0 }}>
                    viaoled@gmail.com
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
