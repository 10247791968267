import { Button, Card } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsGrid1X2 } from "react-icons/bs";
import { Login } from "api/auth";
import { setCookie, getCookie, delete_cookie } from "helpers";
import { CustomInput, CustomInputPass } from "components/fields";
import { siteName } from "config";
import { notification } from "antd";
import { handleToast } from "components/toast";
export const LoginPage = () => {
  const [data, setData] = useState();
  const [api, contextHolder] = notification.useNotification();

  const handleChange = (e) => {
    let datas = { ...data };
    const { name, value } = e.target;
    datas[name] = value;
    setData(datas);
  };

  const handleSubmit = () => {
    Login(data)
      .then(({ data }) => {
        setCookie(`${siteName}_token`, data.auth_token);
        setCookie("name", data?.name || data.email);
        window.localStorage.setItem(`${siteName}_token`, data.auth_token)
        if (getCookie("pro_id")) {
          window.location.pathname = `product/${getCookie("pro_id")}/details`;
          delete_cookie("pro_id");
        } else {
          window.location.pathname = "/";
        }
        handleToast(api, [200, { message: "Login Successfully" }]);
      })
      .catch((err) => {
        console.log(err);
        handleToast(api, err);
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      style={{
        height: "100vh",
        backgroundColor: "#ff000080",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {contextHolder}
      <Card
        style={{ textAlign: "center", width: 450, margin: 15, padding: 12 }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            gap: 8,
            color: "black",
          }}
        >
          <BsGrid1X2 style={{ fontSize: 18 }} />
          <div>Home</div>
        </Link>
        <div
          style={{ margin: "1rem 0", fontWeight: "bolder", fontSize: "25px" }}
        >
          Welcome to Viao
        </div>
        <p></p>
        <div style={{ textAlign: "initial" }}>
          <p style={{ padding: 0, margin: 0 }}>Email *</p>
          <CustomInput
            onChange={handleChange}
            name="username"
            value={data?.username}
            required={true}
            placeholder="Enter your Email"
          />
        </div>
        <div style={{ textAlign: "initial" }}>
          <p style={{ padding: 0, margin: 0 }}>Password *</p>
          <CustomInputPass
            onChange={handleChange}
            name="password"
            value={data?.password}
            required={true}
            placeholder="Enter your Password"
          />
        </div>
        <div style={{ textAlign: "initial" }}>
          <Link
            to="/forgot-password"
            style={{ padding: 0, margin: 0, textDecoration: "none" }}
          >
            Forgot Password ?
          </Link>
        </div>
        <Button
          style={{
            margin: "1rem 0",
            width: "100%",
            background: "#ff000080",
            color: "#fff",
          }}
          htmlType="submit"
        >
          Login
        </Button>
        <div style={{ margin: "0.6rem 32px" }}>
          <Link
            to="/sign"
            style={{ padding: 0, margin: 0, textDecoration: "none" }}
          >
            Signup Now
          </Link>
        </div>
      </Card>
    </form>
  );
};
