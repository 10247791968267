import makeRequest from "api";
import { siteName } from "config";
import { getCookie, headers, jsonHeaders } from "helpers";

export function fetchProducts({ type, order, group }) {
  return makeRequest({
    uri: `/v1/products?product_type=${type || ""}&order=${order || ""}&product_group=${group || ""}`,
    header: { ...jsonHeaders },
  });
}

export function createOrder(id) {
  return makeRequest({
    uri: `/v1/products/${id}/order`,
    method: "POST",
    header: headers(),
  }
  );
}

export function confirmOrder(id, data) {
  return makeRequest({
    uri: `/v1/products/${id}/payment_call_back`,
    method: "PUT",
    header: headers(),
    body: JSON.stringify(data)
  });
}